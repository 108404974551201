<template>
  <PopUp :show="modal" @hideModal="$emit('hideModal')" class="modal--s" bgColor="rgba(0,0,0,0.4)">
    <div class="blog">
      <h5>{{ item.comment }}</h5>
      <h6>{{ item.title }}</h6>
      <p v-for="(i, idx) in item.comment_info" :key="idx">
        <span>{{ i }}</span>
      </p>
    </div>
  </PopUp>
</template>
<script>
import PopUp from "@/components/PopUp.vue";

export default {
  props: { item: { type: Object, default: null }, modal: { type: Boolean, default: false } },
  components: {
    PopUp,
  },
};
</script>
<style lang="scss" scoped>
.blog {
  height: 100%;
  padding: 0 15px;
  overflow-y: auto;
  h5 {
    max-height: 70rem;
    overflow-y: auto;
    @include type($fs: 18px, $lh: 1.23, $fw: 600);
    margin-bottom: 15px;
    padding-right: 10px;
    color: $black;
    @media (max-height: 499px) and (max-width: 899px), (max-width: $sm) {
      font-size: 16px;
    }
  }
  h6 {
    @include type($fs: 16px, $fw: 500);
    margin-bottom: 5px;
    color: $black;
    @media (max-height: 499px) and (max-width: 899px), (max-width: $sm) {
      font-size: 14px;
    }
  }
  p {
    @include type($fw: 400);
    color: $black;
  }
  span {
    color: $black;
  }
}
</style>