<template>
  <div class="page">
    <div class="page__header" style="margin-bottom: 25px">
      <h1 style="margin-top: 10px">Arttextstyle</h1>
      <BaseInput
        class="form-control--grey form-control--xs"
        style="max-width: 65rem"
        placeholder="Search"
        :icon="true"
        :timeout="true"
        :element="tableData.search"
        @input="searchItems"
      >
        <template #icon>
          <IconSearch color="#000" height="16" width="16"></IconSearch>
        </template>
      </BaseInput>
    </div>
    <div class="page__header">
      <HorisontalTabsList :tabs="tabs"></HorisontalTabsList>
    </div>
    <Table
      :loading="loading"
      :items="items"
      :headerItems="headerItems"
      :tableData="tableData"
      :hasMorePages="hasMorePages"
      @sortTable="sortTable"
      @clickAction="clickAction"
      @loadMore="loadMore"
    ></Table>
    <Comment v-if="modal" :modal="modal" :item="item" @hideModal="modal = false" />
  </div>
</template>
<script>
import BaseInput from "@/components/inputs/BaseInput.vue";
import IconSearch from "@/components/icons/IconSearch.vue";
import HorisontalTabsList from "@/components/HorisontalTabsList.vue";
import IconButton from "@/components/buttons/IconButton.vue";
import IconPlusBold from "@/components/icons/IconPlusBold.vue";
import Table from "@/components/Table.vue";
import Comment from "./Comment.vue";
import { mapMutations, mapGetters, mapActions } from "vuex";

export default {
  components: {
    BaseInput,
    IconSearch,
    HorisontalTabsList,
    IconButton,
    IconPlusBold,
    Table,
    Comment,
  },
  data() {
    return {
      tabs: [
        {
          title: "Arttextstyle",
          path: "/admin/arttextstyle/arttextstyle",
          key: "arttextstyle",
        },
        {
          title: "Comments",
          path: "/admin/arttextstyle/comments",
          key: "comments",
        },
      ],
      modal: false,
      item: {},
      tableData: {
        page: 1,
        sort: "title",
        order: "asc",
        search: "",
      },
    };
  },
  async created() {
    await this.apiGetComments(this.tableData);
  },
  computed: {
    ...mapGetters("comments", {
      items: "getItems",
      headerItems: "getItemsHeaders",
      loading: "getLoading",
      hasMorePages: "getHasMorePages",
    }),
  },
  methods: {
    ...mapActions("comments", ["apiGetComments", "apiDeleteComment"]),

    async sortTable({ sort, order }) {
      let data = { ...this.tableData, page: 1, sort, order };
      let success = await this.apiGetComments(data);
      if (success) {
        this.tableData = data;
      }
    },
    async loadMore() {
      if (this.hasMorePages) {
        let data = { ...this.tableData, page: this.tableData.page + 1 };
        let success = await this.apiGetComments(data);
        if (success) {
          this.tableData = data;
        }
      }
    },
    async searchItems(val) {
      if (this.tableData.search != val) {
        let data = { ...this.tableData, page: 1, search: val };
        let success = await this.apiGetComments(data);
        if (success) {
          this.tableData = data;
        }
      }
    },
    async clickAction({ key, id, item }) {
      switch (key) {
        case "delete": {
          let result = await this.confirmRemoveItem(item);
          if (result) {
            this.modal = true;
          }
          break;
        }
        case "view": {
          this.item = item;
          this.modal = true;
          break;
        }
      }
    },
    confirmRemoveItem(item) {
      this.$swal({
        title: `Delete Comment for ${item.title}`,
        html: "<h5>Are you sure? <br/> This cannot be undone.",
        showCancelButton: true,
        showConfirmButton: false,
        showDenyButton: true,
        denyButtonText: "Delete",
        cancelButtonText: "Cancel",
      }).then(result => {
        if (result.value === false) {
          this.removeItem(item.id);
        }
      });
    },
    async removeItem(id) {
      let result = await this.apiDeleteComment(id);
      if (result) {
        let data = { ...this.tableData, page: 1 };
        let success = await this.apiGetComments(data);
        if (success) {
          this.tableData = data;
        }
        this.modal = false;
      }
    },
  },
};
</script>